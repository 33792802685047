import { AuthService, UserService } from '@abcfinlab/auth';
import { ConfigState } from '@abcfinlab/core';
import { NewsManager } from '@abcfinlab/news';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, skipWhile, throttleTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Direction } from '../../../models/enums/Direction.enum';
import { VisibilityState } from '../../../models/enums/VisibilityState.enum';

@UntilDestroy()
@Component({
    selector: 'l7-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('toggle', [
            state(
                VisibilityState.HIDDEN,
                style({ transform: 'translateY(-100%)' }),
            ),
            state(
                VisibilityState.VISIBLE,
                style({ transform: 'translateY(0)' }),
            ),
            transition('* => *', animate('200ms ease-in')),
        ]),
    ],
})
export class HeaderComponent implements AfterViewInit {

    private _visible: boolean = true;

    public stage: string;

    @Input()
    floatable: boolean = false;

    public isMobile: Observable<boolean>;

    constructor(
        public authService: AuthService,
        private readonly _location: Location,
        private readonly _router: Router,
        private readonly _newsManager: NewsManager,
        private readonly _userService: UserService,
        private readonly _store: Store,
    ) {
        this.stage = environment.stage;
        this.isMobile = this._store.select(ConfigState.getMobile);
    }

    get canGoBack(): boolean {
        let result = false;

        if (this._router.navigated) {
            result = this._location.path(false) === ''
            || this._location.path(false)?.endsWith('/private')
            || this._location.path(false)?.endsWith('/login')
            || this._location.path(false)?.endsWith('/logout')
            || this._location.path(false)?.endsWith('remote/summary')
            || this._location.path(false).includes('gwg/pdf-view')
                ? false
                : true;
        }

        return result;
    }

    @HostBinding('@toggle')
    get toggle(): VisibilityState {
        return this._visible ? VisibilityState.VISIBLE : VisibilityState.HIDDEN;
    }

    get newsCount(): Observable<number> {
        return this._newsManager.unreadCount;
    }

    get hasRealmLogins(): Observable<boolean> {
        return this._userService.userInfo.pipe(
            map(x => x.realm === 'LOGINS'),
        );
    }

    public ngAfterViewInit(): void {
        const scroll$ = fromEvent(window, 'scroll').pipe(
            skipWhile(() => !this.floatable),
            throttleTime(10),
            map(() => window.pageYOffset),
            pairwise(),
            map(([y1, y2]): Direction => (y2 < y1 ? Direction.UP : Direction.DOWN)),
            distinctUntilChanged(),
            share(),
            untilDestroyed(this),
        );

        const scrollUp$ = scroll$.pipe(
            filter(direction => direction === Direction.UP),
            untilDestroyed(this),
        );
        const scrollDown$ = scroll$.pipe(
            filter(direction => direction === Direction.DOWN),
            untilDestroyed(this),
        );

        scrollUp$.subscribe(() => this._visible = true);
        scrollDown$.subscribe(() => this._visible = false);
    }

    public showNews(): void {
        this._newsManager.show('news');
    }

    public onBack(): void {
        this._location.back();
    }

}
