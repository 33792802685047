import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as blobUtil from 'blob-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DialogService } from '../../../../../../../apps/shell/src/app/private/services/dialog/dialog.service';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../../../../../../../apps/shell/src/app/shared/modals/generic-dialog/generic-dialog.component';
import { PictureType } from '../../models/picture-type.enum';
import { PicturesService } from '../../services/pictures.service';
import { ContractDocumentCreationModalComponent } from '../contract-document-creation-modal/contract-document-creation.modal';
import { LeasingQuoteIdService } from '../../services/leasing-quote-id.service';
import { QuoteService } from '@abcfinlab/api/global';

@UntilDestroy()
@Component({
    selector: 'gwg-flow-decision',
    templateUrl: './flow-decision.component.html',
    styleUrls: ['./flow-decision.component.scss'],
})
export class FlowDecisionComponent implements OnInit {

    disableRemote: boolean = true;

    private readonly filesData: FormData = new FormData();
    private readonly _isIdentificationRequired = new BehaviorSubject<boolean>(null);

    private dialogRef: MatDialogRef<ContractDocumentCreationModalComponent>;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly _leasingQuoteService: LeasingQuoteIdService,
        private readonly _quoteService: QuoteService,
        private readonly picturesService: PicturesService,
        private readonly _dialogService: DialogService,
        public dialog: MatDialog,
    ) {
        this._leasingQuoteService.isIdentificationRequired.pipe(untilDestroyed(this))
            .subscribe(x => this._isIdentificationRequired.next(x));
    }

    goRemote() {
        if (!this._isIdentificationRequired.getValue()) {
            void this.router.navigate(['../signing-link'], { relativeTo: this.route });
            return;
        }
        this.disableRemote = true;
        this.dialogRef = this.dialog.open(ContractDocumentCreationModalComponent, {
            width: '500px',
            disableClose: true,
            data: {
                text: 'Vertragslink',
            },
        });

        // we can not unsubscribe this because we need it after component destroy
        this.dialogRef.afterClosed().subscribe((result) => {
            this.picturesService.clearDocumentStorage().subscribe();
            if (result === 'error') {
                void this.router.navigate(['../../quotes/detail'], { relativeTo: this.route });
            }
        });

        // we can not unsubscribe this because we need it after component destroy
        this._quoteService.storeIdFiles({ leasingQuoteId: this._leasingQuoteService.getLeasingQuoteId(), body: this.filesData as unknown as { idFront: Blob; idBack: Blob } })
            .subscribe((result) => {
                void this.router.navigate(['../signing-link'], { relativeTo: this.route });
            }, (error) => {
                if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'beneficial_owner_missing') {
                    this.dialog.closeAll();
                    const data: GenericDialogData = {
                        id: 'dialog_beneficial_owner_missing',
                        image: 'assets/images/image-failure.svg',
                        body: `dialogs.${error.error.error}`,
                        positiveText: 'global.close',
                    };
                    this._dialogService.openDialog(GenericDialogComponent, { minHeight: undefined }, data).afterClosed()
                        .pipe(untilDestroyed(this))
                        .subscribe(() => {
                            this.dialog.closeAll();
                        });
                    return;
                }
                this.dialogRef.componentInstance.data = {
                    error: {
                        message: 'error.generic_error',
                        button_text: 'global.cancel',
                    },
                };
            });
    }

    goLocal(): void {
        void this.router.navigate(['../complete-verification'], { relativeTo: this.route });
    }

    ngOnInit() {
        combineLatest([
            this.picturesService.getIdentificationDocument(PictureType.Frontside),
            this.picturesService.getIdentificationDocument(PictureType.Backside),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([frontSide, backSide]) => {
                if (frontSide && backSide) {
                    const frontSideFileExtension = this.picturesService.getDocumentFileExtension(frontSide);
                    this.filesData.set('idFront', blobUtil.dataURLToBlob(frontSide), `idFront${frontSideFileExtension}`);
                    const backSideFileExtension = this.picturesService.getDocumentFileExtension(backSide);
                    this.filesData.set('idBack', blobUtil.dataURLToBlob(backSide), `idBack${backSideFileExtension}`);
                    this.disableRemote = false;
                } else if (!this._isIdentificationRequired.getValue()) {
                    this.filesData.append('idFront', new Blob([JSON.stringify(null)], { type: 'application/json' }));
                    this.filesData.append('idBack', new Blob([JSON.stringify(null)], { type: 'application/json' }));
                }
            });
    }

}
