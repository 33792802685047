import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[l7AddError]',
})
export class AddErrorDirective implements OnInit {

    @Input() errorText: string;
    @Input() set hasError(value) {
        const errorElement = this.elementRef.nativeElement.querySelector('span');
        if (value) {
            this.elementRef.nativeElement.classList.add('error');
            errorElement.style.display = 'block';
        } else {
            this.elementRef.nativeElement.classList.remove('error');
            if (errorElement) {
                errorElement.style.display = 'none';
            }
        }
    }

    constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) { }

    ngOnInit() {
        const child = this.renderer.createElement('span');
        child.innerHTML = this.errorText;
        child.classList.add('input-error');
        child.style.display = 'none';
        this.renderer.appendChild(this.elementRef.nativeElement, child);
    }

}
