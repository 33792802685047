import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'l7-check-cam-modal',
    templateUrl: './check-cam-modal.component.html',
    styleUrls: ['./check-cam-modal.component.scss'],
})
export class CheckCamModalComponent {

    constructor(
        private readonly _dialogRef: MatDialogRef<CheckCamModalComponent>,
    ) {
    }

    public onClose(): void {
        this._dialogRef.close();
    }

}
