import { QuoteDeletionService } from '@abcfinlab/api/global';
import { BusyBoxService } from '@abcfinlab/ui';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { TranslationFacade } from '../../../../../../../libs/core/src/Services/TranslationFacade';
import { QuoteService } from '../../../private/services/quote/quote.service';
import { LesseeState } from '../../../state/Leesee.state';

export interface DeleteConfirmationDialogData {
    quoteId: string;
    objectName: string;
    deleteType: 'ALL_VERSIONS' | 'VERSION';
}

@UntilDestroy()
@Component({
    selector: 'l7-delete-confirmation-dialog',
    templateUrl: './delete-confirmation-dialog.component.html',
    styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationDialogComponent implements OnInit {

    public lesseeID: string;
    public confirmationMsg: string;
    public confirmationMsgAll: string;
    public confirmationMsgVersion: string;
    public confirmationMsgPrefix: string;

    constructor(
        private readonly _quoteService: QuoteService,
        private readonly quoteDeleteService: QuoteDeletionService,
        private readonly _translationFacade: TranslationFacade,
        private readonly dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
        private readonly busyBox: BusyBoxService,
        private readonly _store: Store,
    @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmationDialogData,
    ) {
        this._store.select(LesseeState.selectedLesseeID).pipe(take(1))
            .subscribe(x => this.lesseeID = x);
        this.confirmationMsgAll = this._translationFacade.instant('dialogs.calculation_delete_confirmation_body_all');
        this.confirmationMsgVersion = this._translationFacade.instant('dialogs.calculation_delete_confirmation_body_version');
        this.confirmationMsgPrefix = this._translationFacade.instant('dialogs.calculation_delete_confirmation_body_prefix');
    }

    ngOnInit() {
        if (this.data.deleteType === 'ALL_VERSIONS') {
            this.confirmationMsg = this.confirmationMsgAll + this.confirmationMsgPrefix;
        } else {
            this.confirmationMsg = this.confirmationMsgVersion.concat(' ')
                .concat(this.data.objectName + this.confirmationMsgPrefix);
        }
    }

    onConfirmDelete() {
        let deletion$: Observable<any>;
        if (this.data.deleteType === 'ALL_VERSIONS') {
            deletion$ = this.quoteDeleteService.deleteLeasingQuoteWithAllVersion({ quoteId: this.data.quoteId });
        } else {
            deletion$ = this.quoteDeleteService.deleteLeasingQuoteVersion({ quoteId: this.data.quoteId });
        }
        this.busyBox.show('', null, deletion$.pipe(
            concatMap(() => this._quoteService.fetchQuotesListByLesseeUUID(this.lesseeID)),
            untilDestroyed(this),
        )).subscribe(() => {
            this.dialogRef.close();
        }, error => this.dialogRef.close());
    }

}
