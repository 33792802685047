import { Component, Input } from '@angular/core';

@Component({
    selector: 'gwg-identification-pictures',
    templateUrl: './identification-pictures.component.html',
    styleUrls: ['./identification-pictures.component.scss'],
})
export class IdentificationPicturesComponent {

    @Input() frontsidePicture: string = '';

    @Input() backsidePicture: string = '';

    @Input() isFrontsidePdf: boolean;

    @Input() isBacksidePdf: boolean;

    constructor() { }

}
