// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { WhatsNewViewPresenter } from './WhatsNewViewPresenter';

// #endregion

/**
 * The `WhatsNewView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-what-new-view',
    templateUrl: './WhatsNewView.html',
    styleUrls: ['./WhatsNewView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        WhatsNewViewPresenter,
    ],
})
export class WhatsNewView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: WhatsNewViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `WhatsNewView` class.
     *
     * @public
     */
    public constructor(presenter: WhatsNewViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `WhatsNewView`
     *
     * @public
     * @readonly
     */
    public get presenter(): WhatsNewViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
