/* apps/shell/src/app/shared/components/header/header.component.scss */
:host {
  box-shadow: 0 8px 16px 0 rgba(68, 68, 68, 0.14);
  height: 60px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}
:host header {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
}
:host header .header-col {
  display: flex;
  align-items: center;
  width: 33.33%;
  padding: 0 16px;
}
:host header .header-col.left {
  text-align: left;
  display: flex;
  justify-content: flex-start;
}
:host header .header-col.center {
  text-align: center;
  display: flex;
  justify-content: center;
}
:host header .header-col.center img {
  max-height: 30px;
}
:host header .header-col.right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
:host mat-icon {
  color: #84939d;
}
/*# sourceMappingURL=header.component.css.map */
