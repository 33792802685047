/* apps/shell/src/app/shared/components/profile-menu/profile-menu.component.scss */
.profile-menu {
  margin: 8px 0 0 0;
}
.profile-menu fieldset ~ fieldset {
  margin-top: 16px;
}
.profile-menu legend ~ * {
  margin-top: 8px;
}
.profile-menu .avatars-container legend {
  padding-left: 16px;
}
.profile-menu .avatars-container .avatars-avatar-container .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1em;
  text-transform: uppercase;
}
.profile-menu .avatars-container .avatars-avatar-container .avatar.primary {
  color: #19485a;
  background-color: #e7f2f4;
}
.profile-menu .avatars-container .avatars-avatar-container .avatar.secondary {
  color: #910813;
  background-color: #f6e3e6;
}
.profile-menu .avatars-container .avatars-avatar-container .avatar-text-container .avatar-text {
  line-height: 1em;
}
.profile-menu .avatars-container .avatars-avatar-container .avatar-text-container .avatar-text.sub {
  font-size: 0.8em;
  line-height: 0.8em;
}
.profile-menu .avatars-container .avatars-avatar-container .avatar ~ .avatar-text-container {
  margin-left: 8px;
}
.profile-menu .roles-container {
  margin: 0 16px;
}
.profile-menu .roles-container .roles-role-container {
  margin-top: 12px;
  margin-left: -4px;
}
.profile-menu .roles-container .roles-role-container .role {
  font-size: 0.8em;
  color: #19485a;
  background-color: #e7f2f4;
  margin: 4px;
  padding: 4px;
}
.profile-menu .roles-container .roles-role-container .role ~ .role {
  margin-right: 8px;
}
.profile-menu .actions-container legend {
  padding-left: 16px;
}
mat-icon {
  color: #84939d;
}
/*# sourceMappingURL=profile-menu.component.css.map */
