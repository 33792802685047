import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'gwg-signer-data-processing-modal',
    templateUrl: './signer-data-processing-modal.component.html',
    styleUrls: ['./signer-data-processing-modal.component.scss'],
})
export class SignerDataProcessingModalComponent {

    constructor(
        public dialogRef: MatDialogRef<SignerDataProcessingModalComponent>,
    ) { }

}
