import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'padding',
})
export class PaddingPipe implements PipeTransform {

    private readonly _paddingDigits = 8;

    transform(value: any, maxCount = this._paddingDigits, replacement = '0'): string {
        const cast = String(value);
        return cast.padStart(maxCount, replacement);
    }

}
