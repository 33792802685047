import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SignerDTO } from '../../models/signer-dto.interface';

@UntilDestroy()
@Component({
    selector: 'gwg-signer-form',
    templateUrl: './signer-form.component.html',
    styleUrls: ['./signer-form.component.scss'],
})
export class SignerFormComponent implements OnInit {

    @Input() disabled: boolean = false;

    @Input() signer: SignerDTO = {
        first_name: '',
        last_name: '',
        mail_address: '',
        quote_id: '',
    };

    @Output() signerChange: EventEmitter<SignerDTO> = new EventEmitter<SignerDTO>();

    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    signerForm: UntypedFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
    ) { }

    ngOnInit() {
        this.signerForm = this.fb.group({
            first_name: [this.signer.first_name, Validators.minLength(1)],
            last_name: [this.signer.last_name, Validators.minLength(1)],
            mail_address: [this.signer.mail_address, Validators.pattern('[A-Za-z0-9._%-]+\\+?[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}')],
        });

        this.signerForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(state => this.checkForUpdate(state));

        this.signerForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(values => this.updateValues(values));

        if (this.disabled) {
            this.signerForm.disable();
        }
    }

    private updateValues(values: any): void {
        this.signer = { ...this.signer, ...values };
        this.signerChange.emit(this.signer);
    }

    private checkForUpdate(state: any): void {
        if (state === 'INVALID') {
            this.valid.emit(false);
        } else if (state === 'VALID') {
            this.valid.emit(true);
        }
    }

}
