/* apps/shell/src/app/public/pages/imprint/imprint.component.scss */
.box {
  margin-bottom: 16px;
}
.headline {
  margin-bottom: 24px;
}
h4 {
  margin: 0 0 8px 0;
}
/*# sourceMappingURL=imprint.component.css.map */
