import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export enum SuccessIds {
    LOCAL = 'success-local',
    REMOTE = 'success-remote',
}

@Component({
    selector: 'gwg-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss'],
})
export class SuccessComponent {

    @Input() id: string;

    @Input() bodyText: string;

    possibleIds = SuccessIds;

    constructor(
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
    ) { }

    public goToHome(): Promise<boolean> {
        return this._router.navigate(['../../'], {
            relativeTo: this._activatedRoute,
        });
    }

}
